export default {
  views: {
    invalidUrl: 'URLが無効です。',
    successToResetPassword: 'パスワードのリセットが成功しました。',
    resetPassword: 'パスワードを再設定',
    cancellationPolicy: 'キャンセルポリシー',
    agreeOrNot: '上記を読んだ上で同意しました',
    backToLogin: 'ログイン画面に戻る',
    send: '送信',
    resetError:
      'パスワードリセットの有効期限が切れているか、パスワードがすでにリセットされています。',
    passwordRules: {
      title: '注記：パスワードルール',
      tips1: '最低でも8文字以上の文字数で構成されている',
      tips2:
        'パスワードの中に数字や、「@」、「%」、「"」などの記号も混ぜている',
      tips3: 'パスワード内のアルファベットに大文字と小文字の両方を入れている'
    },
    enrollOTP: {
      title: '二要素認証を登録',
      code: '認証コード',
      verify: '認証'
    }
  }
};
